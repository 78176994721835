<template>
  <a-modal
    :get-container="modalRef"
    v-model:visible="visible"
    :mask-closable="false"
    title="车场详情"
    :width="600"
    :after-close="afterClose"
  >
    <template #footer>
      <a-button @click="visible = false">取消</a-button>
    </template>
    <a-form>
      <a-descriptions bordered size="small" :column="2">
        <a-descriptions-item label="车场名称">{{
          parkingCurbVo.name
        }}</a-descriptions-item>
        <a-descriptions-item label="状态">
          <a-tag :color="parkingCurbVo.status == 1 ? 'green' : 'red'">
            {{ parkingCurbVo.status == 1 ? "启用" : "停用" }}
          </a-tag>
        </a-descriptions-item>
        <a-descriptions-item label="总车位数">{{
          parkingCurbVo.total
        }}</a-descriptions-item>
        <a-descriptions-item label="收费规则名称">{{
          parkingCurbVo.parkingLotBillRuleName
        }}</a-descriptions-item>
        <a-descriptions-item label="地址">{{
          parkingCurbVo.total
        }}</a-descriptions-item>
        <a-descriptions-item label="经纬度">{{
          parkingCurbVo.longitude + "," + parkingCurbVo.latitude
        }}</a-descriptions-item>
        <a-descriptions-item label="车场编号">{{
          parkingCurbVo.code
        }}</a-descriptions-item>
      </a-descriptions>
    </a-form>
  </a-modal>
</template>

<script>
import { ref, onMounted, toRefs } from "vue";
import useMessage from "@/hooks/useMessage";
import { detail } from "@/api/parking/parkingLotApi";
import useParkingCurb from "../../../../hooks/parking/useParkingCurb";

export default {
  name: "Edit",
  props: ["modalRef", "id"],
  emits: ["close"],
  setup(props, { emit }) {
    const disabled = ref(false);
    const { parkingCurbVo } = useParkingCurb();
    const { showErr } = useMessage();
    const visible = ref(true);
    let refresh = false;
    const afterClose = () => {
      emit("close", refresh);
    };
    onMounted(async () => {
      try {
        let r2 = await detail(props.id);
        parkingCurbVo.name = r2.data.name;
        parkingCurbVo.code = r2.data.code;
        parkingCurbVo.total = r2.data.total;
        parkingCurbVo.address = r2.data.address;
        parkingCurbVo.longitude = r2.data.longitude;
        parkingCurbVo.latitude = r2.data.latitude;
        parkingCurbVo.lngLat = r2.data.longitude + "," + r2.data.latitude;
        parkingCurbVo.status = r2.data.status;
        parkingCurbVo.parkingLotBillRuleId = r2.data.parkingLotBillRuleId;
        parkingCurbVo.parkingLotBillRuleName = r2.data.parkingLotBillRuleName;
      } catch (e) {
        await showErr(e);
      }
    });
    return {
      ...toRefs(props),
      disabled,
      visible,
      afterClose,
      parkingCurbVo,
    };
  },
};
</script>

<style scoped>
.row_item {
  border: 1px solid gray;
  border-bottom: none;
  height: 35px;
}

.row_item:last-child {
  border-bottom: 1px solid gray;
  height: 35px;
}

.row_item .col_item_title {
  background-color: #c9dbec;
  height: 33.5px;
  padding: 6px;
  border-right: 1px solid gray;
}

.row_item .col_item_content {
  height: 33.5px;
  padding: 6px;
  border-right: 1px solid gray;
}

.row_item .col_item_content:last-child {
  border-right: none;
}
</style>
