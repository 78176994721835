import { reactive, ref } from "vue";
import { list } from "@/api/parking/parkingLotApi";
import useMessage from "@/hooks/useMessage";
import config from "../../config";
const useParkingCurb = () => {
  const { showErr } = useMessage();
  const loading = ref(false);
  const dataList = reactive({ list: [] });
  const total = ref(0);
  const parkingStatus = ref([
    { value: 1, label: "启用" },
    { value: 0, label: "停用" },
  ]);

  const parkingCurbVo = reactive({
    name: "",
    code: "",
    type: config.parkingType.PARING_CURD,
    total: "",
    address: "",
    longitude: "",
    latitude: "",
    status: null,
    lngLat: "",
    parkingLotBillRuleId: null,
    parkingLotBillRuleName: ""
  });

  const columns = [
    {
      title: "序号",
      align: "center",
      width: "80px",
      customRender: ({ index }) => {
        return index + 1;
      },
    },
    {
      title: "车场名称",
      align: "center",
      dataIndex: "name",
    },
    {
      title: "车场编号",
      align: "center",
      dataIndex: "code",
    },
    {
      title: "总车位数",
      align: "center",
      dataIndex: "total",
    },
    {
      title: "收费规则名称",
      align: "center",
      dataIndex: "parkingLotBillRuleName",
    },
    {
      title: "状态",
      align: "center",
      dataIndex: "status",
      slots: { customRender: "status" },
    },
    {
      title: "地址",
      align: "center",
      dataIndex: "address",
      ellipsis: true,
    },
    {
      title: "操作",
      align: "center",
      width: "240px",
      slots: { customRender: "operation" },
    },
  ];
  const loadData = async (page, pageSize) => {
    loading.value = true;
    try {
      let res = await list(params, page, pageSize);
      dataList.list = res.data;
      total.value = res.total;
      loading.value = false;
    } catch (e) {
      loading.value = false;
      await showErr(e);
    }
  };

  const params = reactive({
    name: "",
    type: config.parkingType.PARING_CURD,
    status: null,
  });
  return {
    loading,
    params,
    dataList,
    total,
    parkingStatus,
    parkingCurbVo,
    columns,
    loadData
  };
};
export default useParkingCurb;
